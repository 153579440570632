import { Alert, Box, Button, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import './styles.less';
import { useDispatch, useSelector } from "react-redux";
import { signIn } from "../../../reducers/authSlice";
import { FormattedMessage, useIntl } from "react-intl";


const SignInForm = () => {

    const intl = useIntl();

    const failedToSignIn = useSelector(state => state.auth.failedToSignIn);
    const error = useSelector(state => state.auth.error);

    const dispatch = useDispatch();

    const [username, setUsername] = useState("");
    const [usernameError, setUsernameError] = useState(false);

    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState(false);

    const handleChangeUsername = (event) => {
        setUsername(event.target.value);
    };

    const handleChangePassword = (event) => {
        setPassword(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        setUsernameError(!username);
        setPasswordError(!password);

        if (usernameError || passwordError) {
            return;
        }

        dispatch(signIn({
            username,
            password
        }));
    };

    return (
        <Box className="sign-in-form-box" component="form" onSubmit={handleSubmit}>
            {failedToSignIn &&
                <Alert severity="error">
                    <FormattedMessage id="sign-in-form.auth-error" />
                </Alert>}

            {
                error && <Alert severity="error">
                    {error.detail}
                </Alert>
            }

            <Typography component="h2" variant="h4" className="sign-in-form-title">
                <FormattedMessage id="sign-in-form.title" />
            </Typography>

            <TextField
                error={usernameError}
                fullWidth
                id="username"
                label={intl.formatMessage({ id: "sign-in-form.username" })}
                name="username"
                autoComplete="username"
                autoFocus
                value={username}
                onChange={handleChangeUsername}
            />

            <TextField
                margin="normal"
                error={passwordError}
                fullWidth
                name="password"
                label={intl.formatMessage({ id: "sign-in-form.password" })}
                type="password"
                id="password"
                value={password}
                onChange={handleChangePassword}
            />

            <Button type="submit" variant="contained">
                <FormattedMessage id="sign-in-form.btn-sign-in" />
            </Button>
        </Box>
    )
};

export default SignInForm;
