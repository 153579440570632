import React from "react";
import { useSelector } from "react-redux";

import {
    selectModelingOpen,
    setModelingOpen as setModelingOpenAction,
    selectModelingSelectedRole,
    selectUserRole,
} from "../../../reducers/reports-modeling/userLevelSlice";

import useReduxValue from "../../common/hooks/useReduxValue";

import ReportModelingBox from "../report-modeling-box";

import UserLevelModelingHeader from "./user-level-modeling-header";
import UserLevelRoleModelingBox from "./user-level-role-modeling-box.jsx";
import UserLevelGroupRoleModelingBox from "./user-level-group-role-modeling-box.jsx";

const UserLevelModelingBox = () => {
    const [modelingOpen, setModelingOpen] = useReduxValue(selectModelingOpen, setModelingOpenAction);
    const selectedRole = useSelector(selectUserRole);
    const selectedModelingRole = useSelector(selectModelingSelectedRole)


    const showRoleModelingBox = selectedModelingRole && !selectedModelingRole.isComplex;



    return (

        <ReportModelingBox
            open={modelingOpen}
            onOpenClick={() => setModelingOpen(!modelingOpen)}
        >
            <UserLevelModelingHeader />
            <UserLevelGroupRoleModelingBox selectedRole={selectedRole} />

            {/* <GroupRoleTable rows={rows} ></GroupRoleTable> */}

            {showRoleModelingBox && (
                <UserLevelRoleModelingBox selectedModelingRole={selectedModelingRole} selectedRole={selectedRole} />
            )}
        </ReportModelingBox>
    );
};

export default UserLevelModelingBox;