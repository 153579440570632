import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { usersService } from "../../services/users-service";
import LockManager from "../../utils/lockManager";
import { BUSY_TYPES } from "../../utils/busy";

const lockManager = new LockManager("user");

const initialState = {
    users: [],
    columns: [],
    total: 0,
    selectedIds: [],
    searchString: "",
    pageNumber: 1,
    limitNumber: 10,
    sortData: [],
    filterData: [],
    busy: false,
    busyType: null,
    error: null,
    submit: false,
    currentUser: null,
    currentUserEditable: false,
    currentUserDeleted: true,
    validationErrors: null,
};

export const fetchUsers = createAsyncThunk(
    "admin/fetchUsers",
    async ({ params }) => {
        const response = await usersService.getUsers(params);
        return response;
    }
);

export const fetchUsersColumns = createAsyncThunk(
    "admin/fetchUsersColumns",
    async () => {
        const response = await usersService.getUsersColumns();
        return response;
    }
);

export const fetchUserDetailed = createAsyncThunk(
    "admin/fetchUserDetailed",
    async (username) => {
        const user = await usersService.getUserDetailed(username);
        const lock = await lockManager.lockObject(username);

        return { user, lock };
    }
);

export const createUser = createAsyncThunk(
    "admin/saveUser",
    async (user, { rejectWithValue }) => {
        let response

        try {
            response = await usersService.createUser(user);
        } catch (messages){
            return rejectWithValue(messages)
        }

        return response;
    }
);

export const editUser = createAsyncThunk(
    "admin/editUser",
    async (user, {rejectWithValue}) => {
        let response

        try {
            response = await usersService.editUser(user);
        } catch (messages){
            return rejectWithValue(messages)
        }

        return response;
    }
);

export const changeUserPassword = createAsyncThunk(
    "admin/changeUserPassword",
    async ({ username, newPassword }, {rejectWithValue}) => {
        let response

        try {
            response = await usersService.changeUserPassword(username, newPassword);
        } catch (messages){
            return rejectWithValue(messages)
        }

        return response;
    }
);

export const lockUser = createAsyncThunk(
    "admin/lockUser",
    async ({ username, locked }, {rejectWithValue}) => {
        let response

        try {
            response = await usersService.lockUser(username, locked);
        } catch (messages){
            return rejectWithValue(messages)
        }

        return response;
    }
);

export const deleteUser = createAsyncThunk(
    "admin/deleteUser",
    async (username, {rejectWithValue}) => {
        try {
            await usersService.deleteUser(username);
        } catch (messages){
            return rejectWithValue(messages)
        }

        return username;
    }
);

export const clearCurrentUser = createAsyncThunk(
    "admin/clearCurrentUser",
    async (_, thunkAPI) => {
        const state = thunkAPI.getState();
        const currentUser = state.admin.users.currentUser;
        const currentUserEditable = state.admin.users.currentUserEditable;
        const currentUserDeleted = state.admin.users.currentUserDeleted;

        if (!currentUser) return thunkAPI.rejectWithValue(["Current user is not initialized"]);

        if (currentUserDeleted || !currentUserEditable || !currentUser.username) return;

        const lockResponse = await lockManager.unlockObject(currentUser.username);

        if (!lockResponse.result) {
            return thunkAPI.rejectWithValue(lockResponse.messages);
        }
    }
);

const usersSlice = createSlice({
    name: "users",
    initialState: initialState,
    reducers: {
        setSubmit(state, action){
            state.submit = action.payload;
        },

        setBusy(state, action){
            state.busy = action.payload;
        },

        setInitialCurrentUser(state) {
            state.currentUser = {
                username: "",
                firstName: "",
                lastName: "",
                middleName: "",
                role: "",
                phone: "",
                email: "",
                dateJoined: "",
                password: ""
            };

            state.error = null;
            state.validationErrors = null;
            state.submit = false;
            state.currentUserEditable = true;
            state.currentUserDeleted = false;
        },

        setError(state, action) {
            state.error = action.payload;
        },

        setValidationErrors(state, action) {
            state.validationErrors = action.payload;
        },

        setSelectedIds(state, action) {
            state.selectedIds = action.payload;
        },

        setSearchString(state, action) {
            if (state.searchString !== action.payload) {
                state.pageNumber = 1;
                state.searchString = action.payload;
            }
        },

        setPageNumber(state, action) {
            state.pageNumber = action.payload;
        },

        setLimitNumber(state, action) {
            state.pageNumber = 1;
            state.limitNumber = action.payload;
        },

        setSortData(state, action) {
            state.pageNumber = 1;
            state.sortData = action.payload;
        },

        setFilterData(state, action) {
            state.pageNumber = 1;
            state.filterData = action.payload;
        },
    },
    extraReducers: {
        //get all users
        [fetchUsers.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [fetchUsers.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.error;
        },

        [fetchUsers.fulfilled]: (state, action) => {
            state.busy = false;
            state.users = action.payload.users;
            state.total = action.payload.total;
            state.error = null;
            state.validationErrors = null;
        },

        // fetch user detailed
        [fetchUserDetailed.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
            state.currentUserDeleted = false;
            state.submit = false;
        },

        [fetchUserDetailed.fulfilled]: (state, action) => {
            const { user, lock } = action.payload;

            state.currentUser = user;
            state.busy = false;
            state.currentUserEditable = lock.result;
            state.error = lock.result ? null : lock.messages;
        },

        [fetchUserDetailed.rejected]: (state, action) => {
            state.currentUser = null;
            state.error = action.error;
            state.busy = false;
            state.currentUserEditable = false;
        },

        // clear current user
        [clearCurrentUser.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [clearCurrentUser.fulfilled]: (state) => {
            state.busy = false;
            state.currentUser = null;
            state.currentUserEditable = false;
            state.currentUserDeleted = false;
            state.error = null;
            state.validationErrors = null;
            state.submit = false;
        },

        [clearCurrentUser.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.payload;
        },

        //create users
        [createUser.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.SAVE;
            state.submit = false;
        },

        [createUser.fulfilled]: (state, action) => {
            state.currentUser = action.payload;
            state.busy = false;
            state.submit = true;
            state.error = null;   
            state.validationErrors = null;  
        },

        [createUser.rejected]: (state, action) => {
            state.busy = false;
            const error = action.payload;

            if (!error) return;

            const validationCodes = new Set(["validation"])

            if (error.code && validationCodes.has(error.code)){
                state.validationErrors = error.errors;
            }

            state.error = error
        },

        //edit user
        [editUser.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.SAVE;
            state.submit = false;
        },

        [editUser.fulfilled]: (state, action) => {
            state.busy = false;
            state.submit = true;
            state.error = null;
            state.validationErrors = null;
            state.currentUser = action.payload;
        },

        [editUser.rejected]: (state, action) => {
            state.busy = false;
            const error = action.payload;

            if (!error) return;

            const validationCodes = new Set(["validation"])

            if (error.code && validationCodes.has(error.code)){
                state.validationErrors = error.errors;
            }

            state.error = error
        },

        //delete user

        [deleteUser.fulfilled]: (state, action) => {
            state.busy = false;
            state.error = null;
            state.validationErrors = null;
            state.users = state.users.filter(user => user.id !== action.payload);

            if (state.currentUser?.username === action.payload) {
                state.submit = true;
                state.currentUserDeleted = true;
            }
        },

        [deleteUser.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.payload;
        },

        [deleteUser.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.SAVE;
        },


        // fetch columns
        [fetchUsersColumns.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [fetchUsersColumns.fulfilled]: (state, action) => {
            state.busy = false;
            state.columns = action.payload;
        },

        [fetchUsersColumns.rejected]: (state, action) => {
            state.busy = false;
            state.errors = action.error;
        },

        // change password
        [changeUserPassword.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.SAVE;
        },

        [changeUserPassword.fulfilled]: (state) => {
            state.busy = false;
            state.error = null;   
            state.validationErrors = null;  
        },

        [changeUserPassword.rejected]: (state, action) => {
            state.busy = false;
            const error = action.payload;

            if (!error) return;

            const validationCodes = new Set(["validation"])

            if (error.code && validationCodes.has(error.code)){
                state.validationErrors = error.errors;
            }
        },


        // lock user
        [lockUser.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [lockUser.fulfilled]: (state) => {
            state.busy = false;
            state.currentUser && (state.currentUser.locked = !state.currentUser.locked)
        },

        [lockUser.rejected]: (state, action) => {
            state.busy = false;

            const error = action.payload;

            state.error = error;
        },
    }
});


export const {
    setSubmit, setBusy, setInitialCurrentUser, setError, setValidationErrors,
    setSelectedIds, setSearchString, setPageNumber, setLimitNumber,
    setSortData, setFilterData
} = usersSlice.actions;

export default usersSlice.reducer;
