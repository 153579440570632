import React from "react";
import { Typography } from "@mui/material";
import PropTypes from "prop-types";


const UserProfileHeaderTitle = ({
    fullName,
}) => {



    return (
        <Typography
            component="span"
            className="user-form-page-header-title-user"
        >
            {fullName}
        </Typography>
    );
};

UserProfileHeaderTitle.propTypes = {
    fullName: PropTypes.string,
};

export default UserProfileHeaderTitle;
