import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { MenuItem, Menu, Typography, Box } from "@mui/material";

import { logOut } from "../../../utils/auth";

import { ArrowDownIcon } from "../icons";
import { useNavigate } from "react-router-dom";


const getUserInitials = (user) => {
    let nameInitial = user?.firstName?.[0] || "";
    let surnameInitial = user?.lastName?.[0] || "";
    return nameInitial + surnameInitial;
};

const UserMenu = () => {
    const [profileAnchor, setProfileAnchor] = useState()
    const containerRef = useRef()
    const user = useSelector((state) => state.auth.user);
    const navigate = useNavigate()

    const menuIsOpen = !!profileAnchor

    const onProfileClick = () => {
        navigate('profile')
    }

    return (
        <>
            {user &&
                <Box
                    onClick={() => setProfileAnchor(containerRef.current)}
                    sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
                    ref={containerRef}>

                    <Box sx={{
                        backgroundColor: "#5A5A5A",
                        borderRadius: "50px",
                        width: "35px",
                        height: "35px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        <Typography className="app-drawer-user-menu-title">
                            {getUserInitials(user)}
                        </Typography>
                    </Box>

                    <ArrowDownIcon
                        sx={{
                            width: "12px",
                            marginLeft: "10px",
                            transform: `${menuIsOpen ? "rotate(180deg)" : "none"}`,
                            color: "#FCFCFC"
                        }}
                        color="#FCFCFC"
                    />
                </Box>
            }

            <Menu
                open={menuIsOpen}
                anchorEl={profileAnchor}
                onClick={() => setProfileAnchor(null)}
                onClose={() => setProfileAnchor(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                PaperProps={{
                    sx: {
                        marginTop: "10px",
                        //width: profileAnchor && profileAnchor.offsetWidth,
                        border: `1px solid var(--accent-color-2)`,
                        borderRadius: "5px",
                        "& .MuiList-root": {
                            backgroundColor: `var(--bg-color-3)`,
                            color: `var(--text-color-1)`,
                            padding: "0px"
                        }
                    }
                }}>
                <MenuItem onClick={onProfileClick} key="logoff" sx={{
                    "&:hover": {
                        color: `var(--select-item-paper-color)`
                    }
                }}>
                    <FormattedMessage id="user-menu.btn-profile" />
                </MenuItem>

                <MenuItem onClick={logOut} key="logoff" sx={{
                    "&:hover": {
                        color: `var(--select-item-paper-color)`
                    }
                }}>
                    <FormattedMessage id="user-menu.btn-sign-out" />
                </MenuItem>
            </Menu>
        </>
    );
};

export default UserMenu;
