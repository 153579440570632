import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";

import { Button } from "../buttons";

import { CommonDialog, CommonDialogTitle, CommonDialogContent, CommonDialogActions } from "./common-dialog.jsx";

import "./style.less";
import { Typography } from "@mui/material";

const UserAgreementDialog = ({
    open,
    text,
    onShowAgreementClick,
    onAcceptClick,
}) => {
    const intl = useIntl();

    const title = intl.formatMessage({ id: "start-page.user-agreement-dialog.title" });

    return(
        <CommonDialog open={open}>
            <CommonDialogTitle
                title={title}
                withoutCloseIcon
            />

            <CommonDialogContent>
                <Typography>
                    {text}
                </Typography>
            </CommonDialogContent>

            <CommonDialogActions>
                <Button
                    variant="outlined"
                    onClick={onShowAgreementClick}
                    sx={{
                        color: 'var(--font-4)',
                        borderColor: 'var(--font-4)',
                    }}
                >
                    <FormattedMessage id="start-page.user-agreement-dialog.btn-show-agreement" />
                </Button>
                <Button
                    variant="contained"
                    onClick={onAcceptClick}
                    sx={{
                        color: 'var(--bg-2)',
                        backgroundColor: 'var(--font-2)',
                        '&:hover': {
                            backgroundColor: 'var(--font-2)',
                        }
                    }}
                >
                    <FormattedMessage id="start-page.user-agreement-dialog.btn-accept-agreement" />
                </Button>
            </CommonDialogActions>
        </CommonDialog>
    )
};

UserAgreementDialog.propTypes = {
    text: PropTypes.string,
    open: PropTypes.bool,
    onShowAgreementClick: PropTypes.func,
    onAcceptClick: PropTypes.func,
};

export default UserAgreementDialog;
