import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Button } from "../../../common/buttons"
import { Box } from "@mui/material";

import { ChangePasswordButton } from "../../../common/buttons";
import "./style.less"



const LogsButton = ({ onClick }) => (
    <Button
        variant='outlined'
        sx={{
            borderColor: "var(--font-4)",
            color: "var(--font-4)",
        }}
        onClick={onClick}
    >
        <FormattedMessage id="common.btn-logs" />
    </Button>
);



const UserProfilePageActions = ({
    onShowLogsClick,
    onChangePasswordClick
}) => {
    console.log(onShowLogsClick)
    return (
        <Box className={"user-profile-header-actions"}>
            {/* <LogsButton onClick={onShowLogsClick}></LogsButton> */}
            <ChangePasswordButton onClick={onChangePasswordClick}></ChangePasswordButton>
        </Box>
    );
};

UserProfilePageActions.propTypes = {
    onShowLogsClick: PropTypes.func,
    onChangePasswordClick: PropTypes.func
};

LogsButton.propTypes = {
    onClick: PropTypes.func
}

export default UserProfilePageActions;
