import React from "react";
import { useSelector } from "react-redux";

import {
    selectModelingOpen,
    setModelingOpen as setModelingOpenAction,
    selectModelingSelectedRole, selectSelectedViewRole
} from "../../../reducers/reports-modeling/roleLevelSlice";



import useReduxValue from "../../common/hooks/useReduxValue";

import ReportModelingBox from "../report-modeling-box";

import RoleLevelRoleModelingBox from "./role-level-role-modeling-box.jsx";
import RoleLevelGroupRoleModelingBox from "./role-level-group-role-modeling-box.jsx";

const RoleLevelModelingBox = () => {
    const [modelingOpen, setModelingOpen] = useReduxValue(selectModelingOpen, setModelingOpenAction);
    const selectedRole = useSelector(selectSelectedViewRole)
    const selectedModelingRole = useSelector(selectModelingSelectedRole);
    const showRoleModelingBox = selectedModelingRole && !selectedModelingRole.isComplex;



    return (
        <ReportModelingBox
            open={modelingOpen}
            onOpenClick={() => setModelingOpen(!modelingOpen)}

        >

            <RoleLevelGroupRoleModelingBox selectedRole={selectedRole} />

            {showRoleModelingBox && (
                <RoleLevelRoleModelingBox selectedModelingRole={selectedModelingRole} selectedRole={selectedRole} />
            )}


        </ReportModelingBox>
    );
};

export default RoleLevelModelingBox;