import React from "react";
import PropTypes from "prop-types";

import { EDIT_MODE, EntityFormActions } from "../../../common/entity-form";
import { Button, ChangePasswordButton } from "../../../common/buttons";
import { useIntl } from "react-intl";

const LockUserButton = ({ onClick, isLocked }) => {
    const intl = useIntl();
    const text = intl.formatMessage({ id: isLocked ? "user-form-page.unlock-user-btn" : "user-form-page.lock-user-btn" });

    return (
        <Button
            variant="outlined"
            sx={{
                borderColor: "var(--font-4)",
                color: "var(--font-4)",
            }}
            onClick={onClick}
        >
            {text}
        </Button>
    );
};

const UserFormActions = ({
    mode,
    onDeleteClick,
    onSaveClick,
    onEditClick,
    onShowLogsClick,
    onChangePasswordClick,
    onLockUserClick,
    isLocked
}) => {
    return (
        <EntityFormActions
            mode={mode}
            onDeleteClick={onDeleteClick}
            onSaveClick={onSaveClick}
            onEditClick={onEditClick}
            onShowLogsClick={onShowLogsClick}
            customActions={mode === EDIT_MODE && (
                <>
                    <ChangePasswordButton
                        onClick={onChangePasswordClick}
                    />

                    <LockUserButton
                        isLocked={isLocked}
                        onClick={onLockUserClick}
                    />

                </>
            )}
        />
    );
};

UserFormActions.propTypes = {
    mode: PropTypes.string,
    onDeleteClick: PropTypes.func,
    onSaveClick: PropTypes.func,
    onEditClick: PropTypes.func,
    onShowLogsClick: PropTypes.func,
    onChangePasswordClick: PropTypes.func,
    isLocked: PropTypes.bool, 
    onLockUserClick: PropTypes.func,
};

LockUserButton.propTypes = {
    onClick: PropTypes.func,
    isLocked: PropTypes.bool
}

export default UserFormActions;
