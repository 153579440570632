import React, { useState, useMemo, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Checkbox } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PropTypes from 'prop-types';
import { nanoid } from "@reduxjs/toolkit";

import { getMassDisabled } from "../../../../utils/formViews";
import { 
    getPermissionFieldValuesSearchHelp, 
    getPermissionFieldsSearchHelp, 
    getPermissionsSearchHelp, 
    getObjectTypesSearchHelp, 
    getObjectNamesSearchHelp,
    getValues1CSearchHelp,
    getFields1CSearchHelp,
    getPermissionActvtSearchHelp
} from "../../../../utils/searchHelps";

import { createRowCellContentInner } from "../../../common/table"
import { TrashIcon } from "../../../common/icons";
import { Input, SearchHelpInput, SelectInput } from "../../../common/form-controls";
import SearchHelpDialog from "../../../common/search-helps";
import { CHANGE_STATUS } from "../../../common/const";
import { EntityFormViewTable } from "../../../common/entity-form";
import { MenuItem } from "../../../common/menu";

import PermissionsSyncDialog from "./permissions-sync-dialog.jsx";
import { signItems, PermissionsMassSignPopover } from "./sign-items.jsx";
import PermissionsMassStatusPopover from "./permissions-mass-status-popover.jsx";

import "./styles.less"
import { SYSTEM_TYPES } from "../../../../utils/integration-utils.js";

const getErrorFromValidationErrors = (row, columnId) => {
    return row && row[`error_${columnId}`];
};

const PermissionsView = ({
    permissions,
    isGroup,
    systemType,
    addPermission,
    addPermissions,
    copyPermission,
    deletePermission,
    deletePermissionMass,
    copyPermissionMass,
    editPermission,
    editPermissionMass,
    disabled,
    systemId,
    operations,
    filterData,
    setFilterData,
    searchString,
    setSearchString,
    getDialogFilters,
    errorIndex,
    setErrorScroll
}) => {
    const intl = useIntl();

    const [selectedIds, setSelectedIds] = useState([]);
    const [rowAction, setRowAction] = useState(null);
    const [syncDialogOpen, setSyncDialogOpen] = useState(false);
    const [massSignAnchorEl, setMassSignAnchorEl] = useState(null);
    const [massActiveAnchorEl, setMassActiveAnchorEl] = useState(null);

    const onChangeCell = useCallback((row, column) => (e) => {
        const value = e.target.value;

        editPermission(row, column.id, value);
    }, [editPermission])

    const onOpenSearchHelp = useCallback((row, column) => () => {
        setRowAction({
            type: "search-help",
            row: row,
            field: column.id,
        })
    }, [])


    const createRowCellContent = useMemo(() => (row, column, selected, onSelect, getCellValue) => {

        const error = getErrorFromValidationErrors(row, column.id)

        switch (column.type) {
            case "search-help": {
                let hasError = error ? (row[column.id]?.toLowerCase()  === error.value?.toLowerCase() && error["type"] === "validation") : false

                if (error && error.code === "duplicate"){
                    hasError = true
                }

                return (
                    <SearchHelpInput
                        disabled={disabled}
                        error={hasError}
                        errorMessage={error && error.message}
                        warning={error ? (row[column.id]?.toLowerCase() === error.value?.toLowerCase() && error["type"] === "integration_warning") : false}
                        warningMessage={error && error.message}
                        showFormHelpers={false}
                        showInnerHelpers
                        value={row[column.id]}
                        fullWidth
                        onOpenSearchHelp={onOpenSearchHelp(row, column)}
                        onChange={onChangeCell(row, column)} />
                )
            }

            case "input": {
                return (
                    <Input
                        disabled={disabled}
                        value={row[column.id]}
                        error={error ? row[column.id] === error.value : false}
                        errorMessage={error && error.message}
                        fullWidth
                        onChange={onChangeCell(row, column)} />
                )
            }
            case "boolean":
                return (
                    <Checkbox
                        disabled={disabled}
                        checked={row[column.id]}
                        sx={{
                            "&.Mui-checked": {
                                color: "gray"
                            },
                        }}
                        onClick={() => {
                            editPermission(row, column.id, !row[column.id]);
                        }} />
                )
            case "select-from": {
                return (
                    <SelectInput
                        disabled={disabled}
                        error={error ? error.value === row[column.id] : false}
                        value={row[column.id]}
                        onChange={onChangeCell(row, column)}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        sx={{
                            width: "90px",
                            height: "39px",
                            "&  .MuiOutlinedInput-notchedOutline": {
                                border: "0px"
                            },
                            "&.Mui-error": {
                                border: "1px solid red"
                            }
                        }}>

                        {
                            column.list?.map(item => (
                                <MenuItem
                                    key={column.getItemKey(item)}
                                    value={column.getItemValue(item)}
                                >
                                    {column.getItemText(item)}
                                </MenuItem>
                            ))
                        }
                    </SelectInput>
                )
            }
            default:
                return createRowCellContentInner(row, column, selected, onSelect, getCellValue)
        }
    }, [disabled, editPermission, onChangeCell, onOpenSearchHelp])

    const columnTexts = useMemo(() => ({
        permissionGroup: intl.formatMessage({ id: "function-form-page.subtable-column.permissionGroup" }),
        permission: intl.formatMessage({ id: "function-form-page.subtable-column.permission" }),
        field: intl.formatMessage({ id: "function-form-page.subtable-column.field" }),
        valueFrom: intl.formatMessage({ id: "function-form-page.subtable-column.valueFrom" }),
        valueTo: intl.formatMessage({ id: "function-form-page.subtable-column.valueTo" }),
        active: intl.formatMessage({ id: "function-form-page.subtable-column.active" }),
        description: intl.formatMessage({ id: "function-form-page.subtable-column.description" })
    }), [intl]);

    const columnTexts1C = useMemo(() => ({
        objectType: intl.formatMessage({ id: "function-form-page.subtable-column.objectType" }),
        objectName: intl.formatMessage({ id: "function-form-page.subtable-column.objectName" }),
        field1C: intl.formatMessage({ id: "function-form-page.subtable-column.field" }),
        value: intl.formatMessage({ id: "function-form-page.subtable-column.value" }),
        condition: intl.formatMessage({ id: "function-form-page.subtable-column.condition" }),
        active: intl.formatMessage({ id: "function-form-page.subtable-column.active" }),
        description: intl.formatMessage({ id: "function-form-page.subtable-column.description" })
    }), [intl]);

    const columnsSAP = useMemo(() => [
        {
            id: "select",
            type: "select",
        },
        {
            id: "permissionGroup",
            title: columnTexts.permissionGroup,
            type: "search-help",
            filterable: true,
        },
        {
            id: "permission",
            title: columnTexts.permission,
            type: "search-help",
            filterable: true,
        },
        {
            id: "field",
            title: columnTexts.field,
            type: "search-help",
            filterable: true,
        },
        {
            id: "valueFrom",
            title: columnTexts.valueFrom,
            type: "search-help"
        },
        {
            id: "valueTo",
            title: columnTexts.valueTo,
            type: "search-help"
        },
        {
            id: "sign",
            title: "",
            type: "select-from",
            list: signItems,
            getItemKey: (item) => item.key,
            getItemValue: (item) => item.key,
            getItemText: (item) => item.text
        },
        {
            id: "active",
            title: columnTexts.active,
            type: "boolean"
        },
        {
            id: "actions",
            type: "actions",
            actions: [
                {
                    id: "copy",
                    disabled,
                    IconComponent: ContentCopyIcon,
                    onClick: (_, row) => copyPermission(row.id)
                },
                {
                    id: "delete",
                    disabled,
                    IconComponent: TrashIcon,
                    onClick: (_, row) => deletePermission(row.id)
                }
            ]
        }
    ], [columnTexts, copyPermission, deletePermission, disabled]);

    const columns1C = useMemo(() => [
        {
            id: "select",
            type: "select",
        },
        {
            id: "objectType",
            title: columnTexts1C.objectType,
            type: "search-help",
            filterable: true,
        },
        {
            id: "objectName",
            title: columnTexts1C.objectName,
            type: "search-help",
            filterable: true,
        },
        {
            id: "field1C",
            title: columnTexts1C.field1C,
            type: "search-help",
            filterable: true,
        },
        {
            id: "value",
            title: columnTexts1C.value,
            type: "search-help"
        },
        {
            id: "sign",
            title: "",
            type: "select-from",
            list: signItems,
            getItemKey: (item) => item.key,
            getItemValue: (item) => item.key,
            getItemText: (item) => item.text
        },
        {
            id: "active",
            title: columnTexts1C.active,
            type: "boolean"
        },
        {
            id: "actions",
            type: "actions",
            actions: [
                {
                    id: "copy",
                    disabled,
                    IconComponent: ContentCopyIcon,
                    onClick: (_, row) => copyPermission(row.id)
                },
                {
                    id: "delete",
                    disabled,
                    IconComponent: TrashIcon,
                    onClick: (_, row) => deletePermission(row.id)
                }
            ]
        }
    ], [columnTexts1C, copyPermission, deletePermission, disabled]);

    const columns = systemType === SYSTEM_TYPES.SAP ? columnsSAP : columns1C

    const handleSyncClick = () => {
        setSyncDialogOpen(true);
    };

    const handleMassSignClick = (event) => {
        setMassSignAnchorEl(event.target);
    };

    const handleMassStatusClick = (event) => {
        setMassActiveAnchorEl(event.target);
    };

    const handleSyncDialogCancelClick = () => {
        setSyncDialogOpen(false);
    };

    const handleSyncDialogSyncClick = (defaultPermissionValues) => {
        setSyncDialogOpen(false);

        const newPermissions = defaultPermissionValues.map(defaultValue => ({
            ...defaultValue,
            id: nanoid(),
            sequence: 1,
            sign: "AND",
            active: true,
            changeStatus: CHANGE_STATUS.ADD
        }));

        addPermissions(newPermissions);
    };

    const toolbarActionsSAP = useMemo(() => {
        const massDisabled = getMassDisabled(disabled, selectedIds);

        return [
            {
                id: "mass-delete",
                title: <FormattedMessage id="common.btn-delete" />,
                callback: () => {
                    deletePermissionMass(selectedIds);
                    setSelectedIds([]);
                },
                position: "end",
                variant: "contained",
                disabled: massDisabled
            },
            {
                id: "mass-copy",
                title: <FormattedMessage id="common.btn-copy" />,
                callback: () => {
                    copyPermissionMass(selectedIds);
                    setSelectedIds([]);
                },
                position: "end",
                variant: "contained",
                disabled: massDisabled
            },
            {
                id: "mass-sign",
                title: <FormattedMessage id="permission-view.mass-sign" />,
                callback: handleMassSignClick,
                position: "end",
                variant: "contained",
                disabled
            },
            {
                id: "mass-status",
                title: <FormattedMessage id="permission-view.mass-status" />,
                callback: handleMassStatusClick,
                position: "end",
                variant: "contained",
                disabled
            },
            {
                id: "sync",
                title: <FormattedMessage id="common.btn-sync" />,
                callback: handleSyncClick,
                position: "end",
                variant: "contained",
                disabled
            },
            {
                id: "search",
                type: "search",
                position: "end",
                value: searchString,
                callback: setSearchString
            },
            {
                id: "add",
                title: <FormattedMessage id="permission-view.btn-add-permission" />,
                callback: addPermission,
                position: "begin",
                disabled
            }
        ]
    }, [
        disabled,
        addPermission,
        searchString,
        setSearchString,
        selectedIds,
        copyPermissionMass,
        deletePermissionMass
    ]);

    const toolbarActions1C = useMemo(() => {
        const massDisabled = getMassDisabled(disabled, selectedIds);

        return [
            {
                id: "mass-delete",
                title: <FormattedMessage id="common.btn-delete" />,
                callback: () => {
                    deletePermissionMass(selectedIds);
                    setSelectedIds([]);
                },
                position: "end",
                variant: "contained",
                disabled: massDisabled
            },
            {
                id: "mass-copy",
                title: <FormattedMessage id="common.btn-copy" />,
                callback: () => {
                    copyPermissionMass(selectedIds);
                    setSelectedIds([]);
                },
                position: "end",
                variant: "contained",
                disabled: massDisabled
            },
            {
                id: "mass-sign",
                title: <FormattedMessage id="permission-view.mass-sign" />,
                callback: handleMassSignClick,
                position: "end",
                variant: "contained",
                disabled
            },
            {
                id: "mass-status",
                title: <FormattedMessage id="permission-view.mass-status" />,
                callback: handleMassStatusClick,
                position: "end",
                variant: "contained",
                disabled
            },
            {
                id: "search",
                type: "search",
                position: "end",
                value: searchString,
                callback: setSearchString
            },
            {
                id: "add",
                title: <FormattedMessage id="permission-view.btn-add-permission" />,
                callback: addPermission,
                position: "begin",
                disabled
            }
        ]
    }, [
        disabled,
        addPermission,
        searchString,
        setSearchString,
        selectedIds,
        copyPermissionMass,
        deletePermissionMass
    ]);

    const getPermissionGroups = useCallback((params) => {
        const searchString = params?.search ? params.search.toLowerCase() : "";

        const rows = operations
            .filter(operation => (
                operation.operation.toLowerCase().includes(searchString) ||
                operation.description.toLowerCase().includes(searchString)
            ))
            .map(operation => ({
                key: operation.operation,
                text: operation.description
            }));

        const total = rows.length;

        return { rows, total };
    }, [operations]);

    const getSearchHelpData = useCallback((params, signal) => {
        switch (rowAction?.field) {
            case "permissionGroup":
                return getPermissionGroups(params);

            case "permission":
                return getPermissionsSearchHelp(params, signal, systemId, isGroup);

            case "field":
                return getPermissionFieldsSearchHelp(params, signal, systemId, rowAction?.row?.permission, isGroup);

            case "valueFrom":
            case "valueTo":
                 if (rowAction?.row.field === "ACTVT") {
                    return getPermissionActvtSearchHelp(
                        params, signal, systemId, rowAction?.row.permission, isGroup
                    )
                } else { 
                 
                    return getPermissionFieldValuesSearchHelp(params, signal, systemId, rowAction?.row?.field, isGroup);
                }
                

            case "value":
                return getValues1CSearchHelp(params, signal, systemId, rowAction?.row?.field1C, rowAction?.row?.objectType, isGroup);
            
            case "field1C":
                return getFields1CSearchHelp(params, signal, systemId, isGroup);
            
            case "objectType":
                return getObjectTypesSearchHelp(params, signal, systemId, isGroup);

            case "objectName":
                return getObjectNamesSearchHelp(params, signal, systemId, isGroup, rowAction?.row?.objectType);
        }
    }, [rowAction, getPermissionGroups, systemId, isGroup]);

    const searchHelpColumns = {
        "permissionGroup": [
            { id: "key", title: columnTexts.permissionGroup },
            { id: "text", title: columnTexts.description }
        ],

        "permission": [
            { id: "key", title: columnTexts.permission },
            { id: "text", title: columnTexts.description }
        ],

        "field": [
            { id: "key", title: columnTexts.field },
            { id: "text", title: columnTexts.description }
        ],

        "valueFrom": [
            { id: "key", title: columnTexts.valueFrom },
            { id: "text", title: columnTexts.description }
        ],

        "valueTo": [
            { id: "key", title: columnTexts.valueTo },
            { id: "text", title: columnTexts.description }
        ],

        "objectType": [
            { id: "key", title: columnTexts1C.objectType }
        ],

        "objectName": [
            { id: "key", title: columnTexts1C.objectName },
            { id: "text", title: columnTexts1C.description }
        ],

        "value": [
            { id: "key", title: columnTexts1C.value }
        ],

        "field1C": [
            { id: "key", title: columnTexts1C.field1C },
            { id: "text", title: columnTexts1C.description }
        ],
    };

    const handleSelectRow = useCallback((row, value) => {
        if (value) {
            setSelectedIds(selectedIds => {
                return [...selectedIds, row.id]
            });
            return;
        }

        setSelectedIds(selectedIds => selectedIds.filter(id => id !== row.id));
    }, []);

    const handleSelectAll = useCallback((value) => {
        if (!value) {
            setSelectedIds([]);
            return;
        }

        setSelectedIds(permissions.map(permission => permission.id));
    }, [permissions]);

    const handleSelectSignMass = (sign) => {
        editPermissionMass(selectedIds, "sign", sign, true);
        setMassSignAnchorEl(null);
        setSelectedIds([]);
    };

    const handleSelectActiveMass = (active) => {
        editPermissionMass(selectedIds, "active", active, true);
        setMassActiveAnchorEl(null);
        setSelectedIds([]);
    };

    return (
        <>
            <EntityFormViewTable
                className="permissions-view-table "
                toolbarActions={systemType === SYSTEM_TYPES.SAP ? toolbarActionsSAP : toolbarActions1C}
                rows={permissions}
                columns={columns}
                selectedIds={selectedIds}
                onSelectRow={handleSelectRow}
                onSelectAll={handleSelectAll}
                createRowCellContent={createRowCellContent}
                filterData={filterData}
                setFilterData={setFilterData}
                getFilters={getDialogFilters}
                errorIndex={errorIndex}
                setErrorScroll={setErrorScroll}
            />

            {rowAction?.type === "search-help" && (
                <SearchHelpDialog
                    defaultSearchString={rowAction.row[rowAction.field] || ""}
                    open={rowAction && rowAction.type === "search-help"}
                    readRows={getSearchHelpData}
                    columns={searchHelpColumns[rowAction.field]}
                    selectOneRow={true} 
                    onSubmit={(selectedRow) => {
                        editPermission(rowAction.row, rowAction.field, selectedRow.key)
                        setRowAction(null)
                    }}
                    onCancel={() => {
                        setRowAction(null)
                    }}
                />
            )}

            {syncDialogOpen && (
                <PermissionsSyncDialog
                    systemId={systemId}
                    operations={operations}
                    open={syncDialogOpen}
                    onCancelClick={handleSyncDialogCancelClick}
                    onSyncClick={handleSyncDialogSyncClick}
                    isGroup={isGroup}
                />
            )}

            {massSignAnchorEl && (
                <PermissionsMassSignPopover
                    anchorEl={massSignAnchorEl}
                    onClose={() => setMassSignAnchorEl(null)}
                    onSelect={handleSelectSignMass}
                />
            )}

            {massActiveAnchorEl && (
                <PermissionsMassStatusPopover
                    anchorEl={massActiveAnchorEl}
                    onClose={() => setMassActiveAnchorEl(null)}
                    onSelect={handleSelectActiveMass}
                />
            )}
        </>
    )
}

PermissionsView.propTypes = {
    permissions: PropTypes.arrayOf(PropTypes.object),
    addPermission: PropTypes.func,
    addPermissions: PropTypes.func,
    copyPermission: PropTypes.func,
    deletePermission: PropTypes.func,
    deletePermissionMass: PropTypes.func,
    copyPermissionMass: PropTypes.func,
    editPermission: PropTypes.func,
    editPermissionMass: PropTypes.func,
    disabled: PropTypes.bool,
    validationErrors: PropTypes.object,
    systemId: PropTypes.string,
    systemType: PropTypes.string,
    operations: PropTypes.arrayOf(PropTypes.object),
    tableRef: PropTypes.any,
    filterData: PropTypes.arrayOf(PropTypes.object),
    setFilterData: PropTypes.func,
    searchString: PropTypes.string,
    setSearchString: PropTypes.func,
    isGroup: PropTypes.bool,
    getDialogFilters: PropTypes.func,
    errorIndex: PropTypes.number,
    setErrorScroll: PropTypes.func
}


export default PermissionsView;