import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { acceptUserAgreement, changeUserPasswordInProfile, fetchUser, selectUser, selectUserAgreementText } from "../../../reducers/authSlice";
import { ResetDefaultPasswordDialog, UserAgreementDialog } from "../dialogs";


const StartPage = () => {
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => selectUser(state));
    const userAgreementText = useSelector((state) => selectUserAgreementText(state));
    const validationErrors = useSelector(state => state.auth.validationErrors);

    const [errors, setErrors] = useState(null);

    useEffect(() => {
        if (!validationErrors) return;

        const errorsTmp = validationErrors.reduce((accumulator, error) => {
            accumulator[error.field] = error;

            return accumulator
        }, {});

        setErrors(errorsTmp)

    }, [validationErrors])

    const handleSaveNewPassword = async (newPassword) => {
        await dispatch(changeUserPasswordInProfile({ newPassword })).unwrap()
        dispatch(fetchUser());
    };


    const handleAcceptUserAgreementClick = async() => {
        await dispatch(acceptUserAgreement()).unwrap()
        dispatch(fetchUser());
    };

    const handleShowAgreementClick = async() => {

    };

    return (
        <>
        <div>
            <FormattedMessage id="common-start-page" />
        </div>

        <UserAgreementDialog
            open={!currentUser.acceptedUserAgreement}
            text={userAgreementText.text}
            onAcceptClick={handleAcceptUserAgreementClick}
            onShowAgreementClick={handleShowAgreementClick} 
        />

        <ResetDefaultPasswordDialog
            open={currentUser.shouldChangePassword && currentUser.acceptedUserAgreement}
            onSave={handleSaveNewPassword}
            error={errors?.password}
        />
        </>
    )
}

export default StartPage;