import React from "react";
import PropTypes from 'prop-types';

import { EntityFormHeader } from "../../../common/entity-form";

import UserProfileHeaderTitle from "./user-profile-header-title.jsx";
import UserProfilePageActions from "./user-profile-actions.jsx";

const UserProfilePageHeader = ({
    fullName,  onChangePasswordClick, onShowLogsClick
}) => {

    return (
        <EntityFormHeader>
            <UserProfileHeaderTitle
                fullName={fullName}
            />

            <UserProfilePageActions
                onShowLogsClick={onShowLogsClick}
                onChangePasswordClick={onChangePasswordClick}
            />
        </EntityFormHeader>
    );
};

UserProfilePageHeader.propTypes = {
    fullName: PropTypes.string,
    onSaveClick: PropTypes.func,
    onBackClick: PropTypes.func,
    onEditClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    onChangePasswordClick: PropTypes.func,
    onShowLogsClick: PropTypes.func
}

export default UserProfilePageHeader;
