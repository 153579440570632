import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { nanoid } from "@reduxjs/toolkit";

import { permissionObjectPropTypes, fieldPropTypes } from "./prop-types";
import ReportModelingEditDialogTableRange from "./report-modeling-edit-dialog-table-range.jsx";
import ReportModelingEditDialog from "./report-modeling-edit-dialog.jsx";

import "./styles.less";
import { integrationService } from "../../../services/integration-service.js";


const getOperation = async (operationId, systemId) => {
    if (!operationId || !systemId) {
        return;
    }
    try {
        const operation = await integrationService.getTransactionDetailed(
            operationId, systemId, null, false
        );

        return operation;
    } catch (e) {
        return;
    }
};

const ReportModelingEditDialogRange = ({
    open,
    systemId,
    permissionObject,
    field,
    onClose,
    onApply
}) => {
    const [rows, setRows] = useState([]);

    useEffect(() => {
        setRows(field.values);
    }, [field]);

    const handleApply = () => {
        const applyRows = rows.filter(row => row.from || row.to);
        onApply(field, applyRows);
    };

    const handleChangeCell = async (rowId, changedField, valueRange) => {
        if(field.id === "TCD" && permissionObject.id === "S_TCODE"){
            const operation = await getOperation(valueRange.valueFrom, systemId)
            valueRange.valueFrom = operation?.transaction || valueRange.valueFrom;
        }

        setRows(rows => rows.map(row => {
            if (row.rowId === rowId) {
                const newRow = {...row}

                newRow["from"] = valueRange.valueFrom
                newRow["to"] = valueRange.valueTo

                return newRow
            }
            
            return row
        })); 
    };

    const handleAddRow = () => {
        setRows(rows => [
            ...rows,
            {
                rowId: nanoid(),
                from: "",
                to: ""
            }
        ]);
    };

    const handleDeleteRow = (rowId) => {
        setRows(rows => rows.filter(row => row.rowId !== rowId));
    };

    return (
        <ReportModelingEditDialog
            open={open}
            field={field}
            permissionObject={permissionObject}
            onClose={onClose}
            onApply={handleApply}
        >
            <ReportModelingEditDialogTableRange
                systemId={systemId}
                field={field}
                rows={rows}
                onChangeCell={handleChangeCell}
                onAddRow={handleAddRow}
                onDeleteRow={handleDeleteRow}
            />
        </ReportModelingEditDialog>
    );
};

ReportModelingEditDialogRange.propTypes = {
    open: PropTypes.bool,
    systemId: PropTypes.string,
    permissionObject: permissionObjectPropTypes,
    field: fieldPropTypes,
    onClose: PropTypes.func,
    onApply: PropTypes.func
};

export default ReportModelingEditDialogRange;