import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Box, useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";

import { Button } from "../../common/buttons";
import { SearchInput } from "../../common/form-controls";
import { AdjustmentsIcon, PlusIcon, SearchPlusIcon, SettingsOutlinedIcon } from "../../common/icons";

import "./styles.less";
import breakpoints from "../../common/styles/breakpoints";
import { Menu, MenuItem } from "../../common/menu";

const ReportModelingViewActionButton = ({ className = "", ...props }) => (
    <Button
        className={`report-modeling-views-action-button ${className}`}
        variant="contained"
        {...props}
    />
);

const ActionsMenuButton = ({ actionsDisabled, actionsList }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    return (
        <>
            <ReportModelingViewActionButton
                className="report-modeling-views-action-menu-button"
                disabled={actionsDisabled}
                onClick={(event) => setAnchorEl(event.target)}
            >
                <AdjustmentsIcon />
            </ReportModelingViewActionButton>

            <Menu
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                {actionsList.map(item => (
                    <MenuItem
                        className="report-modeling-views-actions-menu-item"
                        key={item.id}
                        onClick={item.onClick}
                    >
                        <item.Icon />
                        {item.title}
                    </MenuItem>
                ))}
            </Menu>
        </>

    )
};

const ReportModelingViewsActions = ({
    actionsDisabled,
    onSelectClick,
    onManualSelectClick,
    onOrgLevelsClick,
    searchString,
    setSearchString
}) => {
    const intl = useIntl();

    const matches1024 = useMediaQuery(`(min-width: ${breakpoints.minLaptopBreakpoint})`);

    const handleSearchClick = (event) => {
        setSearchString(event.target.value);
    };

    const actionsList = [
        {
            id: "select",
            Icon: PlusIcon,
            title: intl.formatMessage({ id: "report-modeling-views-actions.select" }),
            onClick: onSelectClick
        },
        {
            id: "manual-select",
            Icon: SearchPlusIcon,
            title: intl.formatMessage({ id: "report-modeling-views-actions.manual-select" }),
            onClick: onManualSelectClick
        },
        {
            id: "org-levels",
            Icon: SettingsOutlinedIcon,
            title: intl.formatMessage({ id: "report-modeling-views-actions.org-levels" }),
            onClick: onOrgLevelsClick
        },
    ];

    return (
        <Box className="report-modeling-views-actions">
            {matches1024 ? (
                actionsList.map(action => (
                    <ReportModelingViewActionButton
                        key={action.id}
                        startIcon={<action.Icon />}
                        disabled={actionsDisabled}
                        onClick={action.onClick}
                    >
                        {action.title}
                    </ReportModelingViewActionButton>
                ))
            ) : (
                <ActionsMenuButton
                    actionsDisabled={actionsDisabled}
                    actionsList={actionsList}
                />
            )}

            <SearchInput value={searchString} onSearchClick={handleSearchClick}/>
        </Box>
    );
};

ReportModelingViewActionButton.propTypes = {
    className: PropTypes.string
};

ActionsMenuButton.propTypes = {
    actionsDisabled: PropTypes.bool,
    actionsList: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        Icon: PropTypes.elementType,
        title: PropTypes.string,
        onClick: PropTypes.func
    }))
};

ReportModelingViewsActions.propTypes = {
    actionsDisabled: PropTypes.bool,
    onSelectClick: PropTypes.func,
    onManualSelectClick: PropTypes.func,
    onOrgLevelsClick: PropTypes.func,
    searchString: PropTypes.string,
    setSearchString: PropTypes.func,
};

export default ReportModelingViewsActions;