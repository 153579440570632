import axios from "axios";

import { logOut, getAccessToken, getRefreshToken, setTokens } from "../utils/auth";
import createGuid from "../utils/createGuid";

const _apiBase = `${window.location.origin}/api`;

export const instance = axios.create({
    baseURL: _apiBase,
});

const instanceNoAuth = axios.create({
    baseURL: _apiBase
});

instance.interceptors.request.use((config) => {
    const accessToken = getAccessToken();

    if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
    } else {
        delete config.headers.Authorization
    }

    return config
}, error => Promise.reject(error));

instance.interceptors.response.use(res => res, async error => {
    const config = error.config;
    const refreshToken = getRefreshToken();

    if (config && config.url === "/auth/token/refresh" && error.response && error.response.status === 401) {
        logOut()
        return Promise.reject(error)
    }

    if (config && config.url !== "/auth/token/access" && error.response && error.response.status === 401 && !config._retry && refreshToken) {
        config._retry = true;

        try {
            const responseRefresh = await instance.post("/auth/token/refresh", {
                refresh: refreshToken
            });

            const { access, refresh } = responseRefresh.data;
            setTokens(access, refresh);
            return instance(config);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    return Promise.reject(error);
})

class SodService {
    getCurrentInstance(useAuth) {
        return useAuth ? instance : instanceNoAuth;
    }

    handleError(error, redirectWhenNotFound) {
        if (redirectWhenNotFound &&
            error?.response?.status === 404 &&
            !error?.response?.data &&
            window.location.pathname !== "/not-found") {
            window.location = "/not-found";
        }

        let messages = []

        if (error && error.response && error.response.data && error.response.data.type === "error") {
            throw error.response.data
        }

        if (error && error.response && error.response.data && error.response.data.type === "warning") {
            throw error.response.data
        }

        if (error && error.response && error.response.data && error.response.data.code) {
            throw error.response.data
        }

        if (error && error.response && error.response.data) {
            const data = error.response.data;

            if (Array.isArray(data)) {
                messages = [...messages, ...data]
            }
        }

        throw messages
    }

    async post(path, body, config, useAuth = true) {
        let response;
        const currentInstance = this.getCurrentInstance(useAuth);

        try {
            response = await currentInstance.post(path, body, config)
        } catch (error) {
            this.handleError(error)
        }

        const data = response.data || null;

        return data
    }

    async get(path, config, useAuth=true, redirectWhenNotFound=true) {
        let response;
        const currentInstance = this.getCurrentInstance(useAuth);

        try {
            response = await currentInstance.get(path, config)
        } catch (error) {
            this.handleError(error, redirectWhenNotFound)
        }

        const data = response.data || null;

        return data
    }

    async put(path, body, config, useAuth = true) {
        let response;
        const currentInstance = this.getCurrentInstance(useAuth);

        try {
            response = await currentInstance.put(path, body, config)
        } catch (error) {
            this.handleError(error)
        }

        const data = response.data || null;

        return data;
    }

    async delete(path, body, config, useAuth = true) {
        let response;
        const currentInstance = this.getCurrentInstance(useAuth);

        try {
            response = await currentInstance.delete(path, { ...config, data: body })
        } catch (error) {
            this.handleError(error)
        }

        const data = response.data || null;

        return data;
    }

    generateConfigForGetEntities(params, signal) {
        const result = {}

        if (signal) {
            result.signal = signal
        }

        if (!params) return result;

        const configParams = { ...params };

        if (params.filters?.length > 0) {
            configParams.filters = this.convFiltersForRequest(params.filters);
        }

        if (params.sorters?.length > 0) {
            configParams.sorters = this.convSortersForRequest(params.sorters);
        }

        result.params = configParams

        return result;
    }

    async getFilters(endpoint, params, signal) {
        const config = this.generateConfigForGetEntities(params, signal);
        const data = await this.get(endpoint, config);

        const rows = data?.rows || [];
        const total = data?.count || 0;

        return { rows, total };
    }

    async getColumns(endpoint) {
        const columns = await this.get(endpoint);
        return columns.map(column => this.parseColumn(column));
    }

    convFiltersForRequest(filterData) {
        const filtersStrings = filterData.filter(({ value }) => value).map(({ field, sign, value }) => {
            let stringValue;

            if (Array.isArray(value)) {
                stringValue = value.join(",");
            } else if (value instanceof Date) {
                stringValue = value.toISOString()
            } else {
                stringValue = value;
            }

            return `(${field} ${sign} '${stringValue}')`
        });

        const result = filtersStrings.join(" and ");

        return result;
    }

    convSortersForRequest(sortData) {
        const sortersStrings = sortData
            .filter(({ sign }) => sign)
            .map(({ field, sign }) => `${field} ${sign}`);

        const result = sortersStrings.join(",");

        return result;
    }

    parseColumn(column) {
        return {
            id: column.column_id,
            title: column.title,
            type: column.type,
            sortable: column.sortable,
            filterable: column.filterable
        }
    }

    parseUserActionLog(userActionLog) {
        return {
            id: createGuid(),
            user: userActionLog.user,
            upd_date: userActionLog.upd_date,
            action: userActionLog.action,
            fieldname: userActionLog.fieldname,
            old_value: userActionLog.old_value,
            new_value: userActionLog.new_value,
            additional_information: userActionLog.additional_information
        }
    }
}

export default SodService;
