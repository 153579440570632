import React from "react";
import PropTypes from 'prop-types';

import { EntityFormHeader } from "../../../common/entity-form";

import UserFormHeaderTitle from "./user-form-header-title.jsx";
import UserFormActions from "./user-form-actions.jsx";

const UserFormHeader = ({
    mode, fullName, onSaveClick, onBackClick, onEditClick, onDeleteClick, onChangePasswordClick, onShowLogsClick, onLockUserClick, isLocked
}) => {
    return (
        <EntityFormHeader>
            <UserFormHeaderTitle
                mode={mode}
                fullName={fullName}
                onBackClick={onBackClick}
                isLocked={isLocked}
            />

            <UserFormActions
                mode={mode}
                isLocked={isLocked}
                onDeleteClick={onDeleteClick}
                onSaveClick={onSaveClick}
                onEditClick={onEditClick}
                onShowLogsClick={onShowLogsClick}
                onChangePasswordClick={onChangePasswordClick}
                onLockUserClick={onLockUserClick}
            />
        </EntityFormHeader>
    );
};

UserFormHeader.propTypes = {
    mode: PropTypes.string,
    fullName: PropTypes.string,
    onSaveClick: PropTypes.func,
    onBackClick: PropTypes.func,
    onEditClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    onChangePasswordClick: PropTypes.func,
    onShowLogsClick: PropTypes.func,
    onLockUserClick: PropTypes.func,
    isLocked: PropTypes.bool,
}

export default UserFormHeader;
