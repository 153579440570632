import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser, changeUserPasswordInProfile, setError } from '../../../../reducers/authSlice.js';


import { PageContentBox } from '../../../common/page-content-box';
import { ProgressDialog, ErrorsDialog, ChangePasswordDialog, UserLogDialog } from '../../../common/dialogs';
import useLogs from '../../../common/hooks/useLogs';

import UserProfilePageHeader from "./user-profile-page-header.jsx"
import UserProfileContent from './user-profile-page-content.jsx';

//  import "./style.less";

const UserProfilePage = () => {
    const user = useSelector(selectUser)

    const trialFucn = () => {
        console.log(user)
    }
    const username = user.username;
    const dispatch = useDispatch();

    const busy = useSelector(state => state.auth.busy);
    const busyType = useSelector(state => state.auth.busyType);
    const error = useSelector(state => state.auth.error);
    const validationErrors = useSelector(state => state.auth.validationErrors);

    const [showChangePasswordDialog, setShowChangePasswordDialog] = useState(false);
    const [form, setForm] = useState(null);


    const [formErrors, setFormErrors] = useState(null);

    const fullName = user ? `${user.lastName} ${user.firstName}` : "";

    const { logs, getLogs, openLogs, setOpenLogs } = useLogs("user", username)

    useEffect(() => {
        if (!validationErrors) return;

        const formErrosTmp = validationErrors.reduce((accumulator, error) => {
            accumulator[error.field] = error;

            return accumulator
        }, {});

        setFormErrors(formErrosTmp)

    }, [validationErrors, form])

    useEffect(() => {
        if (user) {
            setForm({
                ...user,
            });
        }
    }, [user]);

    // useEffect(() => {
    //     if (!submit) return;

    //     dispatch(setSubmit(false));

    //     if (mode === ADD_MODE || userDeleted) {
    //         handleBackClick();
    //     }
    // }, [submit, dispatch, mode, userDeleted, handleBackClick]);

    // const handleSaveClick = () => {
    //     const userToSave = {
    //         ...form,
    //         username: form.username?.trim(),
    //         phone: form.phone?.split(" ").join("")
    //     };

    //     if (userToSave.username !== form.username) {
    //         setForm({
    //             ...form,
    //             username: userToSave.username
    //         });
    //     }

    //     if (mode === EDIT_MODE) {
    //         userToSave.password = null;
    //         dispatch(editUser(userToSave));
    //     } else {
    //         dispatch(createUser(userToSave));
    //     }
    // };



    const handleChangePasswordClick = () => {
        setShowChangePasswordDialog(true);
    };

    const handleChangePasswordCancel = () => {
        setShowChangePasswordDialog(false);
    };

    const handleChangePasswordConfirm = async (newPassword) => {
        await dispatch(changeUserPasswordInProfile({ newPassword })).unwrap()
        setShowChangePasswordDialog(false);
    };


    return (
        <>
            {form && (
                <PageContentBox sx={{
                    padding: "20px 14px 21px"
                }}>
                    <UserProfilePageHeader
                        mode={"READ_MODE"}
                        fullName={fullName}
                        onSaveClick={trialFucn}
                        onBackClick={trialFucn}
                        onEditClick={trialFucn}
                        onDeleteClick={trialFucn}
                        onChangePasswordClick={handleChangePasswordClick}
                        onShowLogsClick={getLogs}
                    />

                    <UserProfileContent
                        form={form}
                        setForm={setForm}
                        validationErrors={formErrors}
                    />
                </PageContentBox>
            )
            }

            <ProgressDialog
                open={busy}
                busyType={busyType}
            />

            <ErrorsDialog
                error={error}
                open={error?.type === "error"}
                onClose={() => { dispatch(setError(null)) }}
            />


            {showChangePasswordDialog && (
                <ChangePasswordDialog
                    open={showChangePasswordDialog}
                    onCancel={handleChangePasswordCancel}
                    onConfirm={handleChangePasswordConfirm}
                    error={formErrors?.password}
                />
            )}

            <UserLogDialog
                open={openLogs}
                logs={logs}
                onClose={() => setOpenLogs(false)}
            />
        </>
    );
};

export default UserProfilePage;
